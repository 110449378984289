import React from 'react';
import '../assets/ContactPage.css';

const ContactPage = () => {
  return (
    <div className="faq-container">
      {/* Page Title */}
      <h1 className="faq-page-title">Contact Us</h1>

      {/* Contact Information Section */}
      <section className="faq-section">
        <div className="faq-content">
          <p>
            Do you have questions about the <strong>Intelligent Cloud-Based Network Performance Analyzer (ICNPA)</strong>? Whether you're an IT professional, a network administrator, or exploring a solution to optimize your systems, we’re here to assist. ICNPA is built to deliver reliable insights, streamline network performance, and support infrastructures of any scale.
          </p>
          <p>
            If you’re interested in how ICNPA can monitor key metrics, enhance server operations, or adapt to your unique needs, our team is ready to provide answers. From product details and customization options to technical assistance and licensing plans, we’re committed to helping you make the most of ICNPA’s features.             Contact us today to learn more, resolve any issues, or explore partnership opportunities. We’re here to support you every step of the way.
          </p>

          {/* Sales Inquiries Section */}
          <h3>Sales Inquiries</h3>
          <div className="content-block">
            <p>If you’re interested in learning more about ICNPA’s features, customization options, or pricing, reach out to our sales team:</p>
            <p><strong>Email:</strong> <a href="mailto:sales@icnpa.com">sales@icnpa.com</a></p>
            <p><strong>Phone:</strong> +1-800-123-SALE (7253)</p>
          </div>

          {/* Technical Support Section */}
          <h3>Technical Support</h3>
          <div className="content-block">
            <p>If you’re experiencing technical issues or need assistance with installation and configuration, contact our support team:</p>
            <p><strong>Email:</strong> <a href="mailto:support@icnpa.com">support@icnpa.com</a></p>
            <p><strong>Phone:</strong> +1-800-123-ICNPA (42672)</p>
          </div>

          {/* Contact Form */}
          <h3>Contact Form</h3>
          <p className="centered-text">
            If you’d prefer, fill out the form below, and we’ll get back to you as soon as possible:
          </p>
          <form className="contact-form">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" placeholder="Your Name" required />

            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" placeholder="Your Email" required />

            <label htmlFor="reason">Reason for Contact:</label>
            <select id="reason" name="reason" required>
              <option value="" disabled selected>
                Select a Reason
              </option>
              <option value="sales">Sales Inquiry</option>
              <option value="support">Technical Support</option>
              <option value="general">General Inquiry</option>
            </select>

            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" placeholder="Your Message" rows="5" required></textarea>

            <button type="submit">Submit</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default ContactPage;
