import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/FAQ.css';

const FAQ = () => {
  return (
    <div className="faq-container">
      {/* Page Title */}
      <h1 className="faq-page-title">Frequently Asked Questions</h1>

      {/* FAQ Section */}
      <section className="faq-section">
        <div className="faq-content">
          <div className="faq-item">
            <h4>How does ICNPA help optimize network and server performance?</h4>
            <p>
              ICNPA optimizes network and server performance by providing real-time insights into critical metrics, such as CPU usage, memory utilization, network latency, and bandwidth consumption. By continuously collecting and analyzing this data, ICNPA identifies patterns and anomalies that could indicate potential issues, such as overburdened servers or network bottlenecks. This proactive approach allows administrators to address problems before they escalate, ensuring smooth operations. Additionally, ICNPA’s detailed performance reports help fine-tune resource allocation, enabling optimal use of hardware and network resources to boost overall efficiency.
            </p>
          </div>

          <div className="faq-item">
            <h4>What server and network metrics does ICNPA track?</h4>
            <p>
              ICNPA provides comprehensive tracking of both server and network metrics. On the server side, it monitors CPU usage, RAM utilization, disk I/O, and system uptime, giving administrators a complete overview of server health and performance. For networks, ICNPA tracks key metrics such as network latency, packet loss, bandwidth utilization, and traffic patterns. This holistic monitoring approach ensures that users can identify and mitigate potential bottlenecks or vulnerabilities, whether they're caused by hardware limitations, software inefficiencies, or external threats.
            </p>
          </div>

          <div className="faq-item">
            <h4>How does ICNPA notify users of critical issues?</h4>
            <p>
              ICNPA’s robust alerting system ensures that users are immediately informed when critical thresholds are exceeded. For example, if CPU usage exceeds 90%, bandwidth consumption spikes unexpectedly, or there is a significant increase in network latency, ICNPA triggers real-time notifications. These alerts are delivered via multiple channels, such as email, SMS, or push notifications, depending on user preferences. The system also provides detailed logs and actionable recommendations, enabling users to quickly diagnose and resolve the root cause of the issue, thereby minimizing downtime and maintaining system performance.
            </p>
          </div>

          <div className="faq-item">
            <h4>How do I get started with ICNPA?</h4>
            <p>
              Getting started with ICNPA is straightforward. First, create an account on the ICNPA platform and set up your preferred monitoring environment. Once logged in, you’ll be guided through the initial configuration process, which includes adding your servers and networks to the platform, setting up alert thresholds, and customizing your dashboard. ICNPA’s user-friendly interface ensures that even beginners can quickly adapt to the system, while advanced users can take advantage of its modular design to tailor the platform to their specific needs. Additionally, the platform offers extensive documentation and tutorials to help users make the most of its features.
            </p>
          </div>

          <div className="faq-item">
            <h4>Can I customize the metrics tracked by ICNPA?</h4>
            <p>
              Yes, ICNPA offers extensive customization options to ensure it meets the unique needs of every user. You can define which metrics to track, set custom thresholds for alerts, and choose how data is visualized on your dashboard. For example, you might prioritize tracking server resource usage in a high-performance computing environment, while focusing on bandwidth and traffic patterns in a network-heavy setup. ICNPA also supports integrations with third-party tools, enabling users to extend its functionality and create a seamless workflow. This flexibility ensures that ICNPA adapts to your environment rather than forcing you to adapt to it.
            </p>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section">
        <h3>Ready to start optimizing your network?</h3>
        <p>Get started with ICNPA today and ensure your network and servers are running smoothly.</p>
        <Link to="/contact" className="cta-button">Get Started</Link>
      </section>
    </div>
  );
};

export default FAQ;
