import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../assets/AboutPage.css';
import diagramImage from '../assets/diagram.png';

const AboutPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="home-container">
      {/* Page Title */}
      <h1 className="home-page-title">How ICNPA Works</h1>

      {/* Grid Section */}
      <section className="grid-section">
        {/* Top Row */}
        <div className="grid-box frontend">
          <h3>The Frontend Layer</h3>
          <p>
          Built with React.js, the user-friendly frontend offers a responsive interface for monitoring metrics, tracking performance, and managing alerts. Real-time dashboards display system health and resource usage, while customizable views and interactive visuals help users focus on critical data. With cross-platform access, administrators can stay informed and take action anytime, anywhere.
          </p>
        </div>

        <div className="grid-box backend">
          <h3>The Backend Layer</h3>
          <p>
          Powered by Flask, the backend handles data processing, configurations, and user authentication with a modular design for seamless updates. It supports fast communication with the frontend and ensures data security through encryption, access controls, and automated backups, making it the system's engine.
          </p>
        </div>

        <div className="grid-box data">
          <h3>The Data Layer</h3>
          <p>
          MariaDB powers the secure data layer, storing server and network information while enabling real-time access to metrics. With encryption, automated backups, and query optimization, it supports anomaly detection, historical analysis, and trend forecasting, forming the foundation of ICNPA.
          </p>
        </div>

        {/* Middle Row */}
        <div className="grid-box alerts">
          <h3>Real-Time Alerts</h3>
          <p>
          Receive instant notifications whenever performance thresholds are exceeded, ensuring prompt action to prevent issues. Fully customizable alerts let you define criteria and delivery methods, helping you stay informed and minimize downtime effectively.
          </p>
        </div>

        {/* Bottom Row */}
        <div className="grid-box self-hosting">
          <h3>Self-Hosting Benefits</h3>
          <p>
            ICNPA’s self-hosted platform gives you full control over your data, keeping sensitive information secure within your environment. By eliminating reliance on third-party services, it reduces risks like data breaches and vendor lock-in. Self-hosting enables customization and adaptability to fit your unique needs. Whether managing a home lab or an enterprise, ICNPA empowers you to monitor performance, address issues in real-time, and scale your setup as needed, ensuring secure and flexible network monitoring.
          </p>
          <h3>ICNPA Summary</h3>
          <p>
            ICNPA simplifies network and server monitoring with a modular, scalable design for small setups and large infrastructures alike. Its tools deliver real-time insights, anomaly detection, and performance tracking to help administrators make quick, informed decisions. Featuring intuitive dashboards, customizable alerts, and detailed reporting, ICNPA adapts to your environment while remaining user-friendly. Its flexibility and scalability make it ideal for optimizing networks, troubleshooting, and planning for growth.
          </p>
        </div>

        <div
          className="grid-box diagram"
          onClick={(e) => {
            e.stopPropagation(); 
            toggleModal();
          }}
        >
          <img src={diagramImage} alt="ICNPA Architecture Diagram" className="diagram-image" />
        </div>
      </section>

      {/* Modal for Diagram */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={toggleModal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()} 
          >
            <img src={diagramImage} alt="Expanded ICNPA Architecture Diagram" className="modal-image" />
          </div>
        </div>
      )}

      {/* Blog Section */}
      <section className="blog-section">
        <h2 className="blog-section-title">Latest Blogs</h2>
        <div className="blog-previews">
          {/* Blog Post 1 */}
          <div className="blog-preview">
            <h3>How to Optimize Your Network with ICNPA</h3>
            <p>
              Learn the best practices for monitoring and optimizing your network performance using ICNPA's powerful tools.
            </p>
            <Link to="/blog/optimize-network" className="blog-button">
              Read More
            </Link>
          </div>

          {/* Blog Post 2 */}
          <div className="blog-preview">
            <h3>The Benefits of Self-Hosting Your Monitoring Tools</h3>
            <p>
              Discover the advantages of self-hosting ICNPA and taking control of your network data and security.
            </p>
            <Link to="/blog/self-hosting-benefits" className="blog-button">
              Read More
            </Link>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section">
        <h3>Ready to start optimizing your network?</h3>
        <p>Get started with ICNPA today and ensure your network and servers are running smoothly.</p>
        <Link to="/contact" className="cta-button">Get Started</Link>
      </section>
    </div>
  );
};

export default AboutPage;
