import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import FAQ from './pages/FAQ';
import OptimizeNetwork from './pages/OptimizeNetwork'; 
import SelfHostingBenefits from './pages/SelfHostingBenefits'; 

const App = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/FAQ" element={<FAQ />} />
        {/* Blog Routes */}
        <Route path="/blog/optimize-network" element={<OptimizeNetwork />} />
        <Route path="/blog/self-hosting-benefits" element={<SelfHostingBenefits />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
