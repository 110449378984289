import React, { useState, useEffect } from 'react';
import api from '../api';
import '../assets/HomePage.css';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';

import 'chartjs-adapter-date-fns'; 

ChartJS.register(LineElement, CategoryScale, LinearScale, TimeScale, PointElement, Tooltip, Legend);

const HomePage = () => {
  const [metrics, setMetrics] = useState({
    activeConnections: null,
    bandwidthUtilization: null,
    dnsResolutionTime: null,
    networkLatency: null,
    networkAvailability: null,
    topApplications: null,
    cpuUsage: null,
    ramUsage: null,
    diskUsage: null,
    diskIo: null,
    networkTraffic: null,
    loadAverage: null,
  });

  const [historicData, setHistoricData] = useState({
    activeConnections: [],
    bandwidthUtilization: [],
    cpuUsage: [],
    dnsResolutionTime: [],
    networkLatency: [],
    networkAvailability: [],
    ramUsage: [],
    diskUsage: [],
    diskIo: [],
    networkTraffic: [],
    loadAverage: [],
    topApplications: [],
  });

  const [collapsedSections, setCollapsedSections] = useState(
    Object.keys(historicData).reduce((acc, key) => {
      acc[key] = true; 
      return acc;
    }, {})
  );

  const [loading, setLoading] = useState(false);

  const fetchMetrics = async () => {
    try {
      setLoading(true);
  
      const [
        activeConnections,
        bandwidthUtilization,
        dnsResolutionTime,
        networkLatency,
        networkAvailability,
        topApplications,
        cpuUsage,
        ramUsage,
        diskUsage,
        diskIo,
        networkTraffic,
        loadAverage,
      ] = await Promise.all([
        api.getMetric('active-connections'),
        api.getMetric('bandwidth-utilization'),
        api.getMetric('dns-resolution-time'),
        api.getMetric('network-latency'),
        api.getMetric('network-availability'),
        api.getMetric('top-applications'),
        api.getMetric('cpu-usage'),
        api.getMetric('ram-usage'),
        api.getMetric('disk-usage'),
        api.getMetric('disk-io'),
        api.getMetric('network-traffic'),
        api.getMetric('load-average'),
      ]);
  
      setMetrics({
        activeConnections,
        bandwidthUtilization,
        dnsResolutionTime,
        networkLatency,
        networkAvailability,
        topApplications,
        cpuUsage,
        ramUsage,
        diskUsage,
        diskIo,
        networkTraffic,
        loadAverage,
      });
  
      const [
        activeConnectionsHistory,
        bandwidthUtilizationHistory,
        cpuUsageHistory,
        dnsResolutionTimeHistory,
        networkLatencyHistory,
        networkAvailabilityHistory,
        diskIoHistory,
        diskUsageHistory,
        ramUsageHistory, 
      ] = await Promise.all([
        api.getMetric('active-connections-history', { params: { limit: 100 } }),
        api.getBandwidthUtilizationHistory(),
        api.getCPUUsageHistory(),
        api.getDNSResolutionTimeHistory(),
        api.getNetworkLatencyHistory(),
        api.getNetworkAvailabilityHistory(),
        api.getDiskIoHistory(),
        api.getDiskUsageHistory(),
        api.getMetric('ram-usage-history'), 
      ]);
  
      const formattedConnectionsData = activeConnectionsHistory.map((item) => ({
        timestamp: new Date(item.timestamp),
        value: item.active_connections,
      }));
  
      const formattedBandwidthData = bandwidthUtilizationHistory.map((item) => ({
        timestamp: new Date(item.timestamp),
        downloadSpeed: item.download_speed,
        uploadSpeed: item.upload_speed,
      }));
  
      const formattedCPUUsageData = cpuUsageHistory.map((item) => ({
        timestamp: new Date(item.timestamp),
        value: parseFloat(item.cpu_percentage).toFixed(2),
      }));
  
      const formattedDNSResolutionTimeData = dnsResolutionTimeHistory.map((item) => ({
        timestamp: new Date(item.timestamp),
        value: item.query_time,
      }));
  
      const formattedLatencyData = networkLatencyHistory.map((item) => ({
        timestamp: new Date(item.timestamp),
        value: parseFloat(item.latency),
      }));
  
      const formattedNetworkAvailabilityData = networkAvailabilityHistory.map((item) => ({
        timestamp: new Date(item.timestamp),
        value: item.availability,
      }));
  
      const formattedDiskIoData = diskIoHistory.map((item) => ({
        timestamp: new Date(item.timestamp),
        read: parseFloat(item.disk_read).toFixed(2),
        write: parseFloat(item.disk_write).toFixed(2),
      }));
  
      const formattedDiskUsageData = diskUsageHistory.map((item) => ({
        timestamp: new Date(item.timestamp),
        label: item.label,
        used_space: parseFloat(item.used_space),
        available_space: parseFloat(item.available_space),
      }));
    
      const formattedRAMUsageData = ramUsageHistory.map((item) => ({
        timestamp: new Date(item.timestamp),
        used_memory: parseFloat(item.used_memory),
        free_memory: parseFloat(item.free_memory),
      }));
    
      setHistoricData((prevData) => ({
        ...prevData,
        activeConnections: formattedConnectionsData.reverse(),
        bandwidthUtilization: formattedBandwidthData.reverse(),
        cpuUsage: formattedCPUUsageData.reverse(),
        dnsResolutionTime: formattedDNSResolutionTimeData.reverse(),
        networkLatency: formattedLatencyData.reverse(),
        networkAvailability: formattedNetworkAvailabilityData.reverse(),
        diskIo: formattedDiskIoData.reverse(),
        diskUsage: formattedDiskUsageData.reverse(),
        ramUsage: formattedRAMUsageData.reverse(), 
      }));
    } catch (error) {
      console.error('Error fetching metrics:', error);
    } finally {
      setLoading(false);
    }
  };  

  useEffect(() => {
    fetchMetrics();

    const interval = setInterval(() => {
      fetchMetrics();
    }, 15000); // Refresh every 15 seconds
    return () => clearInterval(interval);
  }, []);

  const renderValues = (values) => {
    if (!values) return 'Not Available';

    if (Array.isArray(values)) {
      return values.map((item, index) => <div key={index}>{item}</div>);
    }

    if (typeof values === 'string') {
      if (values.includes('\n')) {
        return values
          .split('\n')
          .map((line, index) => <div key={index}>{line.trim()}</div>);
      }

      if (values.includes(',')) {
        return values
          .split(',')
          .map((item, index) => <div key={index}>{item.trim()}</div>);
      }

      return <div>{values}</div>;
    }

    return values; 
  };

  const toggleSection = (metric) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [metric]: !prev[metric],
    }));
  };

  const renderChart = (metric) => {
    const data = historicData[metric];

    if (!data || !Array.isArray(data) || data.length === 0) {
        console.warn(`No data available for metric: ${metric}`);
        return <p>No data available for {metric}</p>;
    }

    let chartData;

    if (metric === 'bandwidthUtilization') {
        chartData = {
            labels: data.map((item) =>
                new Date(item.timestamp).toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                })
            ),
            datasets: [
                {
                    label: 'Download Speed (Mbps)',
                    data: data.map((item) => item.downloadSpeed),
                    borderColor: 'rgba(54, 162, 235, 1)',
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    fill: true,
                    tension: 0.4,
                },
                {
                    label: 'Upload Speed (Mbps)',
                    data: data.map((item) => item.uploadSpeed),
                    borderColor: 'rgba(255, 99, 132, 1)',
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    fill: true,
                    tension: 0.4,
                },
            ],
        };
      } else if (metric === 'diskIo') {
        chartData = {
            labels: data.map((item) =>
                new Date(item.timestamp).toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                })
            ),
            datasets: [
                {
                    label: 'Disk Read (KB/s)',
                    data: data.map((item) => item.read),
                    borderColor: 'rgba(54, 162, 235, 1)',
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    fill: true,
                    tension: 0.4,
                },
                {
                    label: 'Disk Write (KB/s)',
                    data: data.map((item) => item.write),
                    borderColor: 'rgba(255, 99, 132, 1)',
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    fill: true,
                    tension: 0.4,
                },
            ],
        };
      } else if (metric === 'diskUsage') {
        const groupedData = data.reduce((acc, item) => {
            const label = item.label || 'Unknown';
            if (!acc[label]) acc[label] = [];
            acc[label].push({
                x: new Date(item.timestamp), 
                y: parseFloat(item.used_space) || 0,
            });
            return acc;
        }, {});
    
        const datasets = Object.keys(groupedData).map((label) => ({
            label: `${label} - Used Space (GB)`,
            data: groupedData[label],
            borderColor:
                label === 'M.2 Main'
                    ? 'rgba(75, 192, 192, 1)' 
                    : label === 'HDD'
                    ? 'rgba(255, 99, 132, 1)' 
                    : 'rgba(54, 162, 235, 1)', 
            fill: false,
            tension: 0.4,
        }));
    
        chartData = {
            datasets, 
        };
    
        const chartOptions = {
          responsive: true,
          plugins: {
              tooltip: {
                  callbacks: {
                      label: (context) => {
                          const value = context.raw.y || context.raw; 
                          return `${context.dataset.label}: ${value} GB`;
                      },
                      title: (tooltipItems) => {
                          const { parsed } = tooltipItems[0];
                          return `Time: ${new Date(parsed.x).toLocaleString()}`;
                      },
                  },
              },
          },
          scales: {
              x: {
                  type: 'time', 
                  time: {
                      unit: 'minute', 
                      tooltipFormat: 'HH:mm:ss', 
                  },
                  title: {
                      display: true,
                      text: 'Time',
                  },
              },
              y: {
                  title: {
                      display: true,
                      text: 'Disk Space (GB)',
                  },
                  beginAtZero: true,
              },
          },
      };
        return <Line data={chartData} options={chartOptions} />;    
    } else if (metric === 'ramUsage') {
        if (!data || !Array.isArray(data) || data.length === 0) {
            console.warn('No historical RAM usage data available.');
            return <p>No data available for RAM Usage</p>;
        }

        chartData = {
            labels: data.map((item) =>
                new Date(item.timestamp).toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                })
            ),
            datasets: [
                {
                    label: `Used RAM (MB) out of 64219 MB`, 
                    data: data.map((item) => parseFloat(item.used_memory) || 0), 
                    borderColor: 'rgba(54, 162, 235, 1)',
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    fill: true,
                    tension: 0.4,
                },
            ],
        };
    } else {
        chartData = {
            labels: data.map((item) =>
                new Date(item.timestamp).toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                })
            ),
            datasets: [
                {
                    label: `${metric.replace(/([A-Z])/g, ' $1')} Over Time`,
                    data: data.map((item) => item.value),
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    fill: true,
                    tension: 0.4,
                },
            ],
        };
    }

    const chartOptions = {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
                label: (context) => {
                    const value = context.raw.y || context.raw; 
                    if (metric === 'bandwidthUtilization') {
                        return `${context.dataset.label}: ${value} Mbps`;
                    } else if (metric === 'diskIo') {
                        return `${context.dataset.label}: ${value} KB/s`;
                    } else if (metric === 'diskUsage') {
                        return `${context.dataset.label}: ${value} GB`;
                    } else if (metric === 'ramUsage') {
                        return `${context.dataset.label}: ${value} MB`;
                    } else {
                        return `${context.dataset.label}: ${value}`;
                    }
                },
                title: (tooltipItems) => {
                    const { dataIndex } = tooltipItems[0];
                    return `Time: ${new Date(data[dataIndex].timestamp).toLocaleString()}`;
                },
            },
        },        
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Time',
                },
            },
            y: {
                title: {
                    display: true,
                    text:
                        metric === 'diskIo'
                            ? 'Rate (KB/s)'
                            : metric === 'diskUsage'
                            ? 'Disk Space (GB)'
                            : metric === 'ramUsage'
                            ? 'RAM (MB)'
                            : 'Value',
                },
                beginAtZero: true,
            },
        },
    };

    return <Line data={chartData} options={chartOptions} />;
};

  return (
    <div className="homepage-container">
      <h1>Welcome to ICNPA!</h1>
      <p>This is the homepage for the Intelligent Cloud-Based Network Performance Analyzer.</p>

      {loading && <div className="loading-overlay">Loading Data...</div>}

      {/* Current Metrics Section */}
      <div className="metrics-container">
        <div className="section-title">
          <h2>Current Metrics</h2>
        </div>
        <div className="metrics-box">
          {Object.keys(metrics).map((key) => (
            <div key={key} className="metric-card">
              <h3>{key.replace(/([A-Z])/g, ' $1')}</h3>
              <p>{renderValues(metrics[key])}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Historical Data Section */}
      <div className="metrics-container">
        <div className="section-title">
          <h2>Historical Data</h2>
        </div>
        {Object.keys(historicData).map((metric) => (
          <div key={metric} className="collapsible-section">
            <button onClick={() => toggleSection(metric)} className="collapsible-button">
              {collapsedSections[metric] ? '+' : '-'} {metric.replace(/([A-Z])/g, ' $1')} Historical Data
            </button>
            {!collapsedSections[metric] && <div className="chart-container">{renderChart(metric)}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePage;
